<template>
  <ion-page class="ion-page">
    <event-form
      v-if="event"
      :event="event"
      :event-type="event.event_category.name"
      :loading="loading"
      :error="error"
      :disabled="disabled"
      @submit="updateEvent"
    />
  </ion-page>
</template>

<script>
import EventForm from "@/components/forms/EventForm.vue";
import { GET_EDIT_EVENT } from '@/graphql/queries';
import {UPDATE_EVENT} from "@/graphql/mutations";
import { cloneDeep } from "lodash";

export default {
  components: {
    EventForm
  },
  data() {
    return {
      loading: false,
      error: null,
      disabled: false,
    }
  },
  apollo: {
    event: {
      query: GET_EDIT_EVENT,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.$route.params.id
        }
      },
      update: (result) => cloneDeep(result.editEvent),
      error(error) {
        if(error?.graphQLErrors?.[0]?.extensions?.category !== 'authorization') {
          throw new Error(error);
        }

        this.$router.push({ name: this.$routeNames.NOT_ACCESS });
      },
    }
  },
  methods: {
    async updateEvent(data) {
      // eslint-disable-next-line no-unused-vars
      const { repeats, repeat_times, ...restData } = data;

      try {
        const event = await this.$apollo.mutate({
          mutation: UPDATE_EVENT,
          variables: {
            id: this.$route.params.id,
            data: restData,
          }
        });

        await this.$router.push({ name: this.$routeNames.EVENT, params: { id: event.data.updateEvent.id }});
      } catch (error) {
        this.error = error;
      }
    },
  }
}
</script>
